import { createSlice } from '@reduxjs/toolkit'
import { paymentMethodsState } from 'Admin/Store/InitialStates'
import { asyncThunkMutationWithErrors, asyncThunkQueryWithErrors } from '../asyncThunkHandler'
import bankAccountCreateMutation from 'Admin/Mutations/bankAccountCreateMutation'
import paymentMethodUpdateMutation from 'Admin/Mutations/paymentMethodUpdateMutation'
import paymentMethodCreateMutation from 'Admin/Mutations/paymentMethodCreateMutation'
import paymentMethodDeleteMutation from 'Admin/Mutations/paymentMethodDeleteMutation'
import paymentMethodsQuery from 'Admin/Queries/paymentMethodsQuery'
import stripeSetupIntentCreateMutation from 'Admin/Mutations/stripeSetupIntentCreateMutation'
import scheduleUpdateMutation from 'Admin/Mutations/scheduleUpdateMutation'

const createAsyncReducers = (builder, asyncActions) => {
  asyncActions.forEach(action => {
    const { fulfilled, pending, rejected } = action.meta
    builder
      .addCase(action.fulfilled, (state, { payload }) => fulfilled(state, payload))
      .addCase(action.pending, state => pending(state))
      .addCase(action.rejected, (state, { payload }) => rejected(state, payload))
  })
}

export const paymentMethodCreate = asyncThunkMutationWithErrors(
  'paymentMethods/paymentMethodCreate',
  paymentMethodCreateMutation,
  ({ data }) => data.paymentMethodCreate
)

export const mutationBankAccountCreate = asyncThunkMutationWithErrors(
  'paymentMethods/mutationBankAccountCreate',
  bankAccountCreateMutation,
  ({ data }) => data.userBankAccountCreate
)

export const mutationUpdateCard = asyncThunkMutationWithErrors(
  'paymentMethods/mutationUpdateCard',
  paymentMethodUpdateMutation,
  ({ data }) => data.paymentMethodUpdate
)

export const mutationDeletePaymentMethod = asyncThunkMutationWithErrors(
  'paymentMethods/mutationDeletePaymentMethod',
  paymentMethodDeleteMutation,
  ({ data }) => data.paymentMethodDelete
)

export const mutationScheduleUpdate = asyncThunkMutationWithErrors(
  'paymentMethods/mutationScheduleUpdate',
  scheduleUpdateMutation,
  ({ data }) => data.scheduleUpdate
)

export const mutationStripeSetupIntentCreate = asyncThunkMutationWithErrors(
  'paymentMethods/stripeSetupIntentCreate',
  stripeSetupIntentCreateMutation,
  ({ data }) => data.stripeSetupIntentCreate
)

export const queryPaymentMethods = asyncThunkQueryWithErrors(
  'paymentMethods/queryPaymentMethods',
  paymentMethodsQuery,
  ({ data }) => data
)

const defaultPending = state => {
  state.bankAccountsReusable = { data: [], completed: false, loading: true, error: false }
  state.stripeCardsReusable = { data: [], completed: false, loading: true, error: false }
  state.installmentsPendingToPay = { data: [], loading: true, error: false }
}

const defaultRejected = (state, payload) => {
  state.bankAccountsReusable = { data: [], completed: false, loading: false, error: payload }
  state.stripeCardsReusable = { data: [], completed: false, loading: false, error: payload }
  state.installmentsPendingToPay = { data: [], loading: false, error: payload }
}

const paymentMethods = createSlice({
  name: 'paymentMethods',
  initialState: paymentMethodsState,
  reducers: {
    clearErrors: state => {
      state.bankAccountsReusable.error = null
      state.stripeCardsReusable.error = null
    },
    resetLastCard: state => {
      state.stripeCardsReusable.lastAdded = null
    },
    setBankAccountErrors: (state, { payload }) => {
      state.bankAccountsReusable.error = payload
    },
    toggleCompleted: state => {
      state.completed = !state.completed
    },
    toggleDropdown: (state, { payload }) => {
      state.dropdownOpenStates = {
        ...state.dropdownOpenStates,
        [payload]: !state.dropdownOpenStates[payload] || false,
      }
    },
    updatePaymentMethod: (state, { payload }) => {
      const newBankAccounts = state.bankAccountsReusable.data.map(bankAccount => {
        if (payload.id === bankAccount.id) return { ...bankAccount, ...payload }

        return bankAccount
      })

      state.bankAccountsReusable.data = newBankAccounts
    },
  },
  extraReducers: builder => {
    createAsyncReducers(builder, [
      {
        ...paymentMethodCreate,
        meta: {
          fulfilled: (state, payload) => {
            if (payload.error) {
              state.bankAccountsReusable.error = payload.error
              state.stripeCardsReusable.error = payload.error
              return
            }
            const { paymentMethod, user } = payload
            state.bankAccountsReusable = { data: user.bankAccountsReusable, completed: true, loading: false,
                                           error: null }
            state.stripeCardsReusable = { data: user.stripeCardsReusable, completed: true,
                                          lastAdded: paymentMethod, loading: false, error: null }
            state.name = user.name
          },
          pending: defaultPending,
          rejected: defaultRejected,
        },
      },
      {
        ...queryPaymentMethods,
        meta: {
          fulfilled: (state, { isEnvTest, options, user }) => {
            if (state.error) {
              state.bankAccountsReusable.error = state.error
              state.stripeCardsReusable.error = state.error
              return
            }
            state.bankAccounts = { data: user.bankAccounts, completed: true, loading: false, error: null }
            state.bankAccountsReusable = { data: user.bankAccountsReusable, completed: true, loading: false,
                                           error: null }
            state.isEnvTest = isEnvTest
            state.stripeCardsReusable = { data: user.stripeCardsReusable, completed: true, loading: false,
                                          error: null }
            state.id = user.id
            state.installmentsPendingToPay = { data: user.installmentsPendingToPay, completed: true,
                                               loading: false, error: null }
            state.name = user.name
            state.options = options

            if (user.reservation) {
              const { campground, ...reservation } = user.reservation || {}

              state.campground = campground
              state.hasPaymentMethods = (user.bankAccountsReusable.length + user.stripeCardsReusable.length) > 0
              state.reservation = reservation
            }
          },
          pending: defaultPending,
          rejected: defaultRejected,
        },
      },
      {
        ...mutationBankAccountCreate,
        meta: {
          fulfilled: (state, { user }) => {
            state.bankAccountsReusable = { data: user.bankAccountsReusable, completed: true, loading: false,
                                           error: null }
          },
          pending: state => {
            state.bankAccountsReusable = { data: [], completed: true, loading: false, error: null }
          },
          rejected: defaultRejected,
        },
      },
      {
        ...mutationUpdateCard,
        meta: {
          fulfilled: (state, { paymentMethod, user }) => {
            state.bankAccountsReusable = { data: user.bankAccountsReusable, completed: true, loading: false,
                                           error: null }
            state.stripeCardsReusable = { data: user.stripeCardsReusable, completed: true,
                                          lastAdded: paymentMethod, loading: false, error: null }
            state.name = user.name
          },
          pending: defaultPending,
          rejected: defaultRejected,
        },
      },
      {
        ...mutationDeletePaymentMethod,
        meta: {
          fulfilled: (state, { user }) => {
            state.bankAccountsReusable = { data: user.bankAccountsReusable, completed: true, loading: false,
                                           error: null }
            state.stripeCardsReusable = { data: user.stripeCardsReusable, completed: true, loading: false,
                                          error: null }
            state.name = user.name
          },
          pending: defaultPending,
          rejected: defaultRejected,
        },
      },
      {
        ...mutationStripeSetupIntentCreate,
        meta: {
          fulfilled: (state, { stripeSetupIntent }) => {
            state.clientSecret = { data: stripeSetupIntent.clientSecret, completed: true, loading: false,
                                   error: null }
          },
          pending: state => {
            state.clientSecret = { data: null, completed: false, loading: true, error: false }
          },
          rejected: (state, payload) => {
            state.clientSecret = { data: null, completed: false, loading: false, error: payload }
          },
        },
      },
      {
        ...mutationScheduleUpdate,
        meta: {
          fulfilled: (state, { schedule }) => {
            const { reservation } = schedule
            const { user } = reservation
            const { bankAccountsReusable, stripeCardsReusable } = user

            state.bankAccountsReusable = { data: bankAccountsReusable, completed: true, loading: false,
                                           error: null }
            state.hasPaymentMethods = (bankAccountsReusable.length + stripeCardsReusable.length) > 0
            state.reservation.isAutopay = reservation.isAutopay
            state.reservation.schedule = {
              lastAutopayAdded: schedule.paymentMethodAuto,
              ...schedule,
            }
            state.stripeCardsReusable = { data: stripeCardsReusable, completed: true, loading: false,
                                          error: null }
          },
          pending: defaultPending,
          rejected: defaultRejected,
        },
      },
    ])
  },
})

export const {
  clearErrors,
  resetLastCard,
  setBankAccountErrors,
  toggleDropdown,
  toggleCompleted,
  updatePaymentMethod,
} = paymentMethods.actions

export default paymentMethods.reducer
