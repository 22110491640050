import { gql } from 'Utils/apolloClient'
import UserPaymentMethodsDataFragment from 'Admin/Queries/userPaymentMethodsDataFragment'

const scheduleUpdateMutation = gql`
  mutation ScheduleUpdate($input: ScheduleUpdateInput!) {
    scheduleUpdate(input: $input) {
      schedule {
        dayOrdinalized
        id
        installmentNextToPay {
          dueOn
          pricing {
            camperTotal {
              display
            }
          }
        }
        paymentMethodAuto {
          ... on PaymentMethodStripeAch {
            accountHolder
            brand
            isVerified
            lastFour
          }
          ... on PaymentMethodStripeCard {
            brand
            brandIconKey
            expDate
            lastFour
          }
          __typename
        }
        dayOrdinalized
        hasPaymentMethodStripeFailed
        id
        installmentNextToPay {
          dueOn
          pricing {
            camperTotal {
              display
            }
          }
        }
        recurringType
        reservation {
          campground {
            name
          }
          isAutopay
          user {
            ...userPaymentMethodsData
          }
        }
        startsOn
      }
    }
  }

  ${UserPaymentMethodsDataFragment}
`

export default scheduleUpdateMutation
