import { gql } from 'Utils/apolloClient'
import UserPaymentMethodsDataFragment from './userPaymentMethodsDataFragment'

const paymentMethodsQuery = gql`
  query User($id: ID, $reservationUuid: ID, $withReservation: Boolean!) {
    isEnvTest
    user(id: $id) {
      ...userPaymentMethodsData
      id
      installmentsPendingToPay {
        amount {
          display
        }
        dueOn
        id
        links {
          accountInstallmentUrl
        }
      }
      reservation(uuid: $reservationUuid) @include(if: $withReservation) {
        campground {
          name
        }
        isAutopay
        schedule {
          dayOrdinalized
          id
          installmentNextToPay {
            dueOn
            pricing {
              camperTotal {
                display
              }
            }
          }
          paymentMethodAuto {
            ... on PaymentMethodStripeAch {
              accountHolder
              brand
              isVerified
              lastFour
            }
            ... on PaymentMethodStripeCard {
              brand
              brandIconKey
              expDate
              lastFour
            }
            __typename
          }
          dayOrdinalized
          id
          hasPaymentMethodStripeFailed
          installmentNextToPay {
            dueOn
            pricing {
              camperTotal {
                display
              }
            }
          }
          recurringType
          startsOn
        }
      }
    }
  }

  ${UserPaymentMethodsDataFragment}
`

export default paymentMethodsQuery
